export function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    }
    let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + "";
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1 ? str : padLeftZero(str)
            );
        }
    }
    return fmt;
}

function padLeftZero(str) {
    return ("00" + str).substr(str.length);
}

// 产品数据格式化
export function formatGoods(data) {
    data.forEach((res) => {
        if (
            res.spec != undefined &&
            res.spec.length != 0 &&
            res.spec != null &&
            res.spec != ""
        ) {
            res.goods_price = res.spec[0]["goods_price"];
            res.goods_market_price = res.spec[0]["goods_market_price"];
            res.goods_num = res.spec[0]["goods_num"];
            res.all_goods_num = 0;
            res.spec.forEach((specRes) => {
                res.all_goods_num += parseInt(specRes.goods_num);
            });
        }
    });

    return data;
}

export const idCardValidity = (rule, code, callback) => {
    const city = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江 ",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北 ",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏 ",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外 ",
    };
    let tip = "";
    let pass = true;
    if (
        !code ||
        !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(
            code
        )
    ) {
        tip = "身份证号格式错误";
        pass = false;
    } else if (!city[code.substr(0, 2)]) {
        // tip = "地址编码错误"
        tip = "身份证输入错误";
        pass = false;
    } else {
        // 18位身份证需要验证最后一位校验位
        if (code.length === 18) {
            code = code.split("");
            // ∑(ai×Wi)(mod 11)
            // 加权因子
            const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            // 校验位
            const parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
            let sum = 0;
            let ai = 0;
            let wi = 0;
            for (let i = 0; i < 17; i++) {
                ai = code[i];
                wi = factor[i];
                sum += ai * wi;
            }
            // const last = parity[sum % 11];
            if (parity[sum % 11] != code[17]) {
                // tip = "校验位错误"
                tip = "身份证输入错误";
                pass = false;
            }
        }
    }
    if (!pass) {
        callback(new Error(tip));
    } else {
        callback();
    }
};

export function exportFile(data, fileName) {
    const blob = new Blob([data]);
    const link = document.createElement("a");
    link.download = fileName;
    link.style.display = "none";
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const pickerOptions = {
    shortcuts: [
        {
            text: '今天',
            onClick(picker) {
                const date = new Date();
                picker.$emit('pick', [date, date]);
            }
        },
        {
            text: '昨天',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24);
                picker.$emit('pick', [date, date]);
            }
        },
        {
            text: '本月',
            onClick(picker) {
                const date = new Date();
                const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                const start = new Date(date.getFullYear(), date.getMonth(), 1);
                picker.$emit('pick', [start, end]);
            }
        },
        {
            text: '上个月',
            onClick(picker) {
                const date = new Date();
                const end = new Date(date.getFullYear(), date.getMonth(), 0);
                const start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                picker.$emit('pick', [start, end]);
            }
        }
    ]
}

/**
 * @deprecated 防止多次提交
 */
export const clickBtn = (function() {
    let onoff = true;
    return function(fn) {
        if (onoff) {
            onoff = false;
            fn && fn();
            setTimeout(() => {
                onoff = true;
            }, 3000);
        } else {
            console.log("请稍后点击");
        }
    };
})();

export const checkPhone = (rule, value, callback) => {
    if (!(/^1[3456789]\d{9}$/.test(value))) {
        callback(new Error('请输入正确的手机号码!'));
    } else {
        callback()
    }
}
