/**
 *@Author:navies
 *@Date:2021-01-26
 *@Project:后台管理系统
 */
const device = [
//设备管理
    {
        path: "typelist",
        name: "typelist",
        component: () => import("@/views/Admin/device/typelist"),
    }, // 设备类型
    {
        path: "typeAdd",
        name: "typeAdd",
        component: () => import("@/views/Admin/device/typeAdd"),
    }, // 设备类型新增
    {
        path: "devicelist",
        name: "devicelist",
        component: () => import("@/views/Admin/device/device/devicelist"),
    }, // 设备列表
    {
        path: "deviceBatchlist",
        name: "deviceBatchlist",
        component: () =>
            import("@/views/Admin/device/device/deviceBatchlist"),
    }, // 设备批次列表
    {
        path: "deviceBatchAdd",
        name: "deviceBatchAdd",
        component: () => import("@/views/Admin/device/device/deviceBatchAdd"),
    }, // 设备批次录入
    {
        path: "refundlist",
        name: "refundlist",
        component: () => import("@/views/Admin/device/refundlist"),
    }, // 设备退款申请
    {
        path: "ruleslist",
        name: "ruleslist",
        component: () => import("@/views/Admin/device/ruleslist"),
    }, // 设备退款规则

]
export default device;
