import Vue from "vue";
import Router from "vue-router";
import routers from './router/admin/common'

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        // 登录
        {
            path: "/Admin/login",
            name: "login",
            component: () => import("./views/Admin/login.vue"),
        },

        // 后台界面
        {
            path: "/Admin",
            name: "admin_index",
            component: () => import("./views/Admin/index"),
            children: routers,
        },


        // 商家登录
        {
            path: "/Seller/login",
            name: "seller_login",
            component: () => import("./views/Seller/login.vue"),
        },

        // 后台界面
        {
            path: "/Seller/index",
            name: "seller_index",
            component: () => import("./views/Seller/index"),
            children: [
                {
                    path: "/Seller/index",
                    name: "seller_default",
                    component: () => import("./views/Seller/default"),
                }, // 打开默认页面
            ],
        },

        // PC端界面
        {
            path: "/",
            name: "home",
            component: () => import("./views/Admin/index"),
        },
    ],
});
