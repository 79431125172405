/**
 *@Author:navies
 *@Date:2021-01-27
 *@Project:后台管理系统
 */
const trade = [
    //账户明细
    {
        path: "/admin/account/user/list",
        component: () =>
            import ("@/views/Admin/account/user/list"),
    },
    //账户流水
    {
        path: "/admin/account/bill/list",
        name: "accountBillList",
        component: () =>
            import ("@/views/Admin/account/bill/list"),
    },
    //历史记录
    {
        path: "/admin/account/history/index",
        name: "accountHistoryList",
        component: () =>
            import ("@/views/Admin/account/history/index"),
    },
    //推荐人
    {
        path: "/admin/account/user/referrer",
        name: "accountBillList",
        component: () =>
            import ("@/views/Admin/account/user/referrer"),
    },
    //账户查询
    {
        path: "/Admin/account/user/money",
        component: () =>
            import ("@/views/Admin/account/user/money"),
    },
    //账户查询交易记录
    {
        path: "/Admin/account/user/accountBill",
        name: "accountBillListTo",
        component: () =>
            import ("@/views/Admin/account/user/accountBill"),
    },
    //备付金查询
    {
        path: "/Admin/account/pay/inquiry",
        component: () =>
            import ("@/views/Admin/account/pay/inquiry"),
    },
    //贡献值调整
    {
        path: "/Admin/account/pay/contribution-value",
        component: () =>
            import ("@/views/Admin/account/pay/contribution-value"),
    },
    //贡献值日报表
    {
        path: "/Admin/account/pay/contribution-day-report",
        component: () =>
            import ("@/views/Admin/account/pay/contribution-day-report"),
    },
    //交易查询
    {
        path: "order/bestList",
        component: () =>
            import ("@/views/Admin/trade/order/bestList"),
    },
    //精品馆交易
    {
        path: "order/list",
        component: () =>
            import ("@/views/Admin/trade/order/list"),
    },
    // 商户日汇总
    {
        path: "total/list",
        component: () =>
            import ("@/views/Admin/trade/total/list"),
    },
    // 礼包明细
    {
        path: "order/gift-bag-details",
        component: () =>
            import ("@/views/Admin/trade/order/gift-bag-details"),
    },
]
export default trade;
