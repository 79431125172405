/**
 *@Author:navies
 *@Date:2021-01-26
 *@Project:后台管理系统
 */

const content = [
    //任务列表
    {
        path: "check/list",
        name: "taskCheckList",
        component: () => import("@/views/Admin/task/check/list"),
    },
    {
        path: "check/add",
        name: "checkAdd",
        component: () => import("@/views/Admin/task/check/add"),
    },
    //审核列表
    {
        path: "audit/list",
        name: "auditList",
        component: () => import("@/views/Admin/task/audit/list"),
    },
    //审查
    {
        path: "audit/view",
        name: "auditView",
        component: () => import("@/views/Admin/task/audit/view"),
    },
]
export default content
