/**
 *@Author:navies
 *@Date:2021-07-23
 *@Project:后台管理系统
 */

const content = [
    //用户查询列表
    {
        path: "user/index",
        name: "awzgsUserIndex",
        component: () => import("@/views/Admin/awzgs/user/index"),
    },
    {
        path: "user/add",
        name: "awzgsUserAdd",
        component: () => import("@/views/Admin/awzgs/user/add"),
    },
    //订单列表
    {
        path: "order/index",
        name: "awzgsOrderIndex",
        component: () => import("@/views/Admin/awzgs/order/index"),
    },

]
export default content
