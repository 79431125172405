/**
 *@Author:navies
 *@Date:2021-07-23
 *@Project:后台管理系统
 */

const content = [
    //用户查询列表
    {
        path: "user/index",
        name: "shareMallUserIndex",
        component: () => import("@/views/Admin/share-mall/user/index"),
    },
    {
        path: "user/add",
        name: "shareMallUserAdd",
        component: () => import("@/views/Admin/share-mall/user/add"),
    },
    {
        path: "user/binding",
        name: "shareMallUserBinding",
        component: () => import("@/views/Admin/share-mall/user/binding"),
    },
]
export default content
