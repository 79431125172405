/**
 *@Author:navies
 *@Date:2021-12-27
 *@Project:联享数据后台管理系统
 */
export const shareMallBaseUrl = process.env.VUE_APP_API_URL+'system';
/**
 * 惠链接
 * @type {{shareMallUserList: string, shareMallOrderList: string}}
 */
const users = {
    //用户查询
    list: shareMallBaseUrl + "/shareMall/getUserList",
    create: shareMallBaseUrl + "/shareMall/bindBranchCompany",
    delete: shareMallBaseUrl + "/shareMall/untieBranchCompany",
    binding: shareMallBaseUrl + "/shareMall/userUpgrade",
};
export default users;
