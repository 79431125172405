/**
 *@Author:navies
 *@Date:2021-01-06
 *@Project:联盟商家后台管理系统
 */
export const config = {
    qrCodeUrlPath:'http://100yuedata.cn/mp/#/pages/common/h5pay/pay?qrCodeNo=',//收款码生成地址
    aliStaticUrl:'https://hrwj.oss-cn-shenzhen.aliyuncs.com/',//阿里OSS上传地址
    aliOss: {
        region: 'oss-cn-shenzhen',
        accessKeyId: 'LTAI4GA2zuBrfjXcVyV1wymg',
        accessKeySecret: 'I5t9O8TCseQlOCZwEG2UJboZdj7lCu',
        bucket: 'hrwj',
        secure: false
    },
    //阿里视频云地址
    aliVodUrl:'https://adv.100yuedata.cn/',
};
