/**
 *@Author:navies
 *@Date:2021-01-26
 *@Project:后台管理系统
 */

const permission = [
    {
        path: "user/adminuserlist",
        name: "adminuserlist",
        component: () =>
            import("@/views/Admin/permission/user/adminuserlist"),
    }, // 系统管理员
    {
        path: "role/rolelist",
        name: "rolelist",
        component: () => import("@/views/Admin/permission/role/rolelist"),
    }, // 角色列表
    {
        path: "menu/menulist",
        name: "menulist",
        component: () => import("@/views/Admin/permission/menu/menulist"),
    },
]
export default permission
