<template>
  <div id="app">
    <router-view></router-view>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "app",
  components: {
    // HelloWorld
  },
  created() {
    console.log(this.$router);
  }
};
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
  margin: 0;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #333;
}

.qingwu {
  height: 100%;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}
.admin_breadcrumb {
  height: 42px;
  line-height: 42px;
}
  .admin_main_block_left .el-range-editor--small.el-input__inner {
    width: 220px;
  }
  .el-icon-circle-close{
    color: #ffffff;
  }
</style>
