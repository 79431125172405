/**
 *@Author:navies
 *@Date:2021-01-26
 *@Project:后台管理系统
 */
/**
 * 广告联盟
 * @type {({path: string, component: (function(): *), name: string}|{path: string, component: (function(): *), name: string}|{path: string, component: (function(): *), name: string}|{path: string, component: (function(): *), name: string}|{path: string, component: (function(): *), name: string})[]}
 */
const advertising = [
    {
        path: "/admin/adv/mp/mplist",
        name: "mplist",
        component: () => import("@/views/Admin/adv/mp/mplist"),
    }, // 商家推广列表
    {
        path: "/admin/adv/mp/add",
        name: "mpadd",
        component: () => import("@/views/Admin/adv/mp/add"),
    }, // 商家推广编辑
    {
        path: "/admin/adv/advts/advtslist",
        name: "advtslist",
        component: () => import("@/views/Admin/adv/advts/advtslist"),
    }, // 广告列表
    {
        path: "/admin/adv/advts/add",
        name: "advtsadd",
        component: () => import("@/views/Admin/adv/advts/add"),
    }, // 广告编辑

    // 广告主管理
    {path: "main/list", name: "advMainList", component: () => import("@/views/Admin/adv/main/list"),},
    // 审核管理
    {path: "audit/list", name: "advAuditList", component: () => import("@/views/Admin/adv/audit/list"),},
    {path: "audit/add", name: "advAuditAdd", component: () => import("@/views/Admin/adv/audit/add"),},
    // 广告管理
    {path: "items/list", name: "advItemsList", component: () => import("@/views/Admin/adv/items/list"),},
    {path: "items/add", name: "advItemsAdd", component: () => import("@/views/Admin/adv/items/add"),},
    // 财务管理
    {path: "finance/list", name: "advFinanceList", component: () => import("@/views/Admin/adv/finance/list"),},
]
export default advertising;
