/**
 *@Author:navies
 *@Date:2021-01-26
 *@Project:后台管理系统
 */

const merchant = [
    {
        path: "merchant/merchantlist",
        name: "merchantlist",
        component: () =>
            import("@/views/Admin/merchant/merchant/merchantlist"),
    }, // 商家列表
    {
        path: "merchant/merchantUpdate",
        name: "merchantUpdate",
        component: () =>
            import("@/views/Admin/merchant/merchant/merchantUpdate"),
    },
    {
        path: "shop/shoplist",
        name: "shoplist",
        component: () => import("@/views/Admin/merchant/shop/shoplist"),
    }, // 门店列表
    {
        path: "shop/shopEdit",
        name: "shopEdit",
        component: () => import("@/views/Admin/merchant/shop/shopEdit"),
    },
    {
        path: "shop/shopdetail",
        name: "shopdetail",
        component: () => import("@/views/Admin/merchant/shop/shopdetail"),
    }, // 门店详情
    {
        path: "audit/merchantauditlist",
        name: "merchantauditlist",
        component: () =>
            import("@/views/Admin/merchant/audit/merchantauditlist"),
    }, // 门店审核
    {
        path: "audit/merchantIntolist",
        name: "merchantIntolist",
        component: () =>
            import("@/views/Admin/merchant/audit/merchantIntolist"),
    }, // 门店审核
    {
        path: "audit/merchantaudit",
        name: "merchantaudit",
        component: () => import("@/views/Admin/merchant/audit/merchantaudit"),
    }, // 门店修改
    {
        path: "audit/merchantEdit",
        name: "merchantEdit",
        component: () => import("@/views/Admin/merchant/audit/merchantEdit"),
    },  // 门店进件
    {
        path: "audit/into",
        name: "merchantauditinto",
        component: () => import("@/views/Admin/merchant/audit/into"),
    }, // 门店审核详情
    {
        path: "audit/mcauditlist",
        name: "mcauditlist",
        component: () => import("@/views/Admin/merchant/audit/mcauditlist"),
    }, // 商品审核
    {
        path: "audit/mcauditlist-goods",
        name: "mcauditlist-goods",
        component: () =>
            import("@/views/Admin/merchant/audit/mcauditlist-goods"),
    }, // 商品审核详情
    {
        path: "category/categorylist",
        name: "categorylist",
        component: () =>
            import("@/views/Admin/merchant/category/categorylist"),
    },
    //门店会员卡列表
    {
        path: "shop/cardList",
        name: "cardList",
        component: () =>
            import("@/views/Admin/merchant/shop/cardList"),
    },
    {
        path: "shop/shopCardEdit",
        name: "shopCardEdit",
        component: () =>
            import("@/views/Admin/merchant/shop/shopCardEdit"),
    },
    // 广告计划列表
    {
        path: "club/list",
        name: "clubList",
        component: () =>
            import("@/views/Admin/merchant/club/list"),
    },
]
export default merchant
