/**
 *@Author:navies
 *@Date:2021-01-26
 *@Project:后台管理系统
 */

const mall = [
    //惠豆商城
    {
        path: "goods/goodslist",
        name: "goodslist",
        component: () => import("@/views/Admin/mall/goods/goodslist"),
    }, // 商品列表
    {
        path: "goods/add",
        name: "goods_add",
        component: () => import("@/views/Admin/mall/goods/add"),
    }, // 添加商品
    {
        path: "goods/edit",
        name: "goods_edit",
        component: () => import("@/views/Admin/mall/goods/edit"),
    }, // 编辑商品
    {
        path: "order/orderlist",
        name: "orderlist",
        component: () => import("@/views/Admin/mall/order/orderlist"),
    }, // 订单列表
    {
        path: "order/orderDetail",
        name: "orderDetail",
        component: () => import("@/views/Admin/mall/order/detail"),
    }, // 订单详情
    {
        path: "goodstype/typelist",
        name: "typelist",
        component: () => import("@/views/Admin/mall/goodstype/typelist"),
    }, // 类型
    {
        path: "query/querylist",
        name: "querylist",
        component: () => import("@/views/Admin/mall/query/querylist"),
    }, // 查询设置
    {
        path: "order/noticelist",
        name: "noticelist",
        component: () => import("@/views/Admin/mall/order/noticelist"),
    }, // 通知设置
    {
        path: "order/timeoutform",
        name: "timeoutform",
        component: () => import("@/views/Admin/mall/order/timeoutform"),
    }, // 订单超时设置
]
export default mall
