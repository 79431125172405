/**
 *@Author:navies
 *@Date:2021-01-26
 *@Project:后台管理系统
 */
const withdrawal = [
    //提现列表
    {path: "withdrawallist", name: "withdrawallist", component: () => import("@/views/Admin/withdrawal/list/index"),},
    //提现风控列表
    {path: "risk-management", name: "risk-management", component: () => import("@/views/Admin/withdrawal/risk-management/index"),},
    //提现风控编辑
    {path: "risk-management/Add", name: "riskManagementAdd", component: () => import("@/views/Admin/withdrawal/risk-management/add"),},
    //提现申请列表
    {path: "apply/list", name: "withdrawalApplyList", component: () => import("@/views/Admin/withdrawal/apply/list"),},
]
export default withdrawal;
