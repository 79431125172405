/**
 *@Author:navies
 *@Date:2021-01-26
 *@Project:后台管理系统
 */

const channel = [
    {
        path: "operations_center/operationscenterlist",
        name: "operationscenterlist",
        component: () =>
            import(
                "@/views/Admin/channel/operations_center/operationscenterlist"
                ),
    }, // 运营中心列表
    {
        path: "operations_center/add",
        name: "operationscenterAdd",
        component: () =>
            import("@/views/Admin/channel/operations_center/add"),
    }, // 运营中心申请
    {
        path: "service_providers/serviceproviderslist",
        name: "serviceproviderslist",
        component: () =>
            import(
                "@/views/Admin/channel/service_providers/serviceproviderslist"
                ),
    }, // 服务商列表
    {
        path: "service_providers/add",
        name: "serviceprovidersAdd",
        component: () =>
            import("@/views/Admin/channel/service_providers/add"),
    }, // 服务商新增
    {
        path: "extension_workers/extensionworkerslist",
        name: "extensionworkerslist",
        component: () =>
            import(
                "@/views/Admin/channel/extension_workers/extensionworkerslist"
                ),
    }, // BD列表列表
    {
        path: "extension_workers/add",
        name: "extensionworkersAdd",
        component: () =>
            import("@/views/Admin/channel/extension_workers/add"),
    }, // BD列表新增

    /**
     * 省级代理列表
     */
    { path: "agent_province/index",name: "agentProviceList",component: () =>import("@/views/Admin/channel/agent_province/index"),},
    { path: "agent_province/add",name: "agentProviceAdd",component: () =>import("@/views/Admin/channel/agent_province/add"),},
    /**
     * 市级代理列表
     */
    { path: "agent_city/index",name: "agentCityList",component: () =>import("@/views/Admin/channel/agent_city/index"),},
    { path: "agent_city/add",name: "agentCityAdd",component: () =>import("@/views/Admin/channel/agent_city/add"),},
    /**
     * 区级代理列表
     */
    { path: "agent_area/index",name: "agentAreaList",component: () =>import("@/views/Admin/channel/agent_area/index"),},
    { path: "agent_area/add",name: "agentAreaAdd",component: () =>import("@/views/Admin/channel/agent_area/add"),},

    //    分润管理
    {
        path: "fenrun_summary/operationscenter",
        name: "operationscenter",
        component: () =>
            import("@/views/Admin/channel/fenrun_summary/operationscenter"),
    },
    // 运营中心分润
    {
        path: "fenrun_summary/serviceproviders",
        name: "serviceproviders",
        component: () =>
            import("@/views/Admin/channel/fenrun_summary/serviceproviders"),
    },
    // 运营中心分润明细
    {
        path: "fenrun_summary/detail/operationscenter_detail",
        name: "operationscenterDetail",
        component: () => import("@/views/Admin/channel/fenrun_summary/detail/operationscenter_detail"),
    },

    // 服务商分润
    {
        path: "fenrun_summary/serviceproviders",
        name: "serviceproviders",
        component: () =>
            import("@/views/Admin/channel/fenrun_summary/serviceproviders"),
    },
    // 服务商分润明细
    {
        path: "fenrun_summary/detail/serviceproviders_detail",
        name: "serviceprovidersDetail",
        component: () => import("@/views/Admin/channel/fenrun_summary/detail/serviceproviders_detail"),
    },

    // BD分润
    {
        path: "fenrun_summary/extensionworkers",
        name: "extensionworkers",
        component: () =>
            import("@/views/Admin/channel/fenrun_summary/extensionworkers"),
    },
    // BD分润明细
    {
        path: "fenrun_summary/detail/extensionworkers_detail",
        name: "extensionworkersDetail",
        component: () => import("@/views/Admin/channel/fenrun_summary/detail/extensionworkers_detail"),
    },


    //代理中心
    {
        path: "audit/channelauthlist",
        name: "channelauthlist",
        component: () =>
            import("@/views/Admin/channel/audit/channelauthlist"),
    }, // 审核管理
    {
        path: "audit/detail",
        name: "channelauthDetail",
        component: () => import("@/views/Admin/channel/audit/detail"),
    }, // 审核查看
]
export default channel
