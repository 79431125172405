/**
 *@Author:navies
 *@Date:2021-01-26
 *@Project:后台管理系统
 */

const members = [
    {
        path: "members/memberslist",
        name: "memberslist",
        component: () => import("@/views/Admin/members/user/list"),
    }, // 会员列表
    {
        path: "members/membersAdd",
        name: "membersAdd",
        component: () => import("@/views/Admin/members/user/add"),
    }, // 新增、编辑会员
    {
        path: "members/membersDetail",
        name: "membersDetail",
        component: () => import("@/views/Admin/members/user/detail"),
    }, // 会员详情
]
export default members
