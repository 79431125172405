/**
 *@Author:navies
 *@Date:2021-01-26
 *@Project:后台管理系统
 */

const content = [
    {
        path: "feedback/list",
        name: "feedbackList",
        component: () => import("@/views/Admin/content/feedback/list"),
    }, // 反馈列表
    {
        path: "brand/brandlist",
        name: "brandList",
        component: () => import("@/views/Admin/content/brand/list"),
    }, // 品牌故事
    {
        path: "brand/brandAdd",
        name: "brandAdd",
        component: () => import("@/views/Admin/content/brand/add"),
    }, // 品牌故事新增
]
export default content
