/**
 *@Author:navies
 *@Date:2021-07-23
 *@Project:佰悦数据后台管理系统
 */
export const awzgsBaseUrl = process.env.VUE_APP_API_URL+'system';
/**
 * 惠链接
 * @type {{awzgsUserList: string, awzgsOrderList: string}}
 */
const awzgs = {
    //用户查询
    awzgsUserList: awzgsBaseUrl + "/hljuser/getList",
    awzgsUserAdd: awzgsBaseUrl + "/hljuser/add",
    awzgsUserRecharge: awzgsBaseUrl + "/hljorder/addOrder",
    //订单查询
    awzgsOrderList: awzgsBaseUrl + "/hljorder/getList",

};
export default awzgs;
