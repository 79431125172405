/**
 *@Author:navies
 *@Date:2021-01-27
 *@Project:后台管理系统
 */
const trade = [
    //系统配置
    {
        path: "config",
        name: "config",
        component: () => import("@/views/Admin/system/config"),
    },
    // 三要素
    {
        path: "/admin/white_list/three_elements/list",
        name: "threeElementsList",
        component: () => import("@/views/Admin/white_list/three_elements/list"),
    },

    // 菜单列表
    // 区域管理
    {
        path: "/admin/area/city/list",
        name: "citylist",
        component: () => import("@/views/Admin/area/city/list"),
    }, // 城市站点列表
    {
        path: "/admin/area/district/list",
        name: "districtlist",
        component: () => import("@/views/Admin/area/district/list"),
    }, // 区域管理列表
    {
        path: "/admin/area/business/list",
        name: "districtlist",
        component: () => import("@/views/Admin/area/business/list"),
    }, // 商圈管理
    //系统配置
    {
        path: "simulateLoginSMS",
        name: "simulateLoginSMS",
        component: () => import("@/views/Admin/system/simulateLoginSMS"),
    },
]
export default trade;
