import advertising from './advertising'
import device from './device'
import mall from './mall'
import shareMall from './shareMall'
import merchant from './merchant'
import channel from './channel'
import members from './members'
import payment_code from './payment_code'
import content from './content'
import permission from './permission'
import withdrawal from './withdrawal'
import trade from './trade'
import system from './system'
import task from './task'
import activity from './activity'
import awzgs from './awzgs'

const routers = [
    {
        path: "index",
        name: "admin_default",
        component: () => import("@/views/Admin/default"),
    }, // 打开默认页面

    //惠豆商城
    {
        path: "mall",
        name: "mall",
        component: () => import("@/views/Admin/main"),
        children: mall,
    },
    //惠豆商城
    {
        path: "share-mall",
        name: "share-mall",
        component: () => import("@/views/Admin/main"),
        children: shareMall,
    },

    // 联盟商家
    {
        path: "merchant",
        name: "merchant",
        component: () => import("@/views/Admin/main"),
        children: merchant,
    },

    //代理管理
    {
        path: "channel",
        name: "channel",
        component: () => import("@/views/Admin/main"),
        children: channel,
    },


    // 收款码管理
    {
        path: "payment_code",
        name: "payment_code",
        component: () => import("@/views/Admin/main"),
        children: payment_code,
    },

    //设备管理
    {
        path: "device",
        name: "device",
        component: () => import("@/views/Admin/main"),
        children: device,
    },

    //会员粉丝
    {
        path: "members",
        name: "members",
        component: () => import("@/views/Admin/main"),
        children: members,
    },


    //内容管理
    {
        path: "content",
        name: "content",
        component: () => import("@/views/Admin/main"),
        children: content,
    },


    //系统
    {
        path: "system",
        name: "system",
        component: () => import("@/views/Admin/main"),
        children: system,
    },

    //   管理员列表
    {
        path: "permission",
        name: "permission",
        component: () => import("@/views/Admin/main"),
        children: permission,
    },



    //广告管理
    {
        path: "adv",
        name: "advertising",
        component: () => import("@/views/Admin/main"),
        children: advertising,
    },

    //交易查询
    {
        path: "trade",
        name: "trade",
        component: () => import("@/views/Admin/main"),
        children: trade,
    },

    //
    {
        path: "withdrawal",
        name: "withdrawal",
        component: () => import("@/views/Admin/main"),
        children: withdrawal,
    },

    // 任务管理
    {
        path: "task",
        name: "task",
        component: () => import("@/views/Admin/main"),
        children: task,
    },

    // 活动管理
    {
        path: "activity",
        name: "activity",
        component: () => import("@/views/Admin/main"),
        children: activity,
    },

    // 惠链接
    {
        path: "awzgs",
        name: "awzgs",
        component: () => import("@/views/Admin/main"),
        children: awzgs,
    },

]
export default routers
