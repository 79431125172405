/**
 *@Author:navies
 *@Date:2021-01-26
 *@Project:后台管理系统
 */

const payment_code = [
    // {path:"qrcode",name:"paymentcodeList",component:()=>import("@/views/Admin/payment_code/qrcode")}, // 收款二维码
    {
        path: "paymentcodelist",
        name: "paymentcodeList",
        component: () => import("@/views/Admin/payment_code/paymentcodelist"),
    }, // 收款二维码
    {
        path: "batchcodelist",
        name: "batchcodelist",
        component: () => import("@/views/Admin/payment_code/batchcodelist"),
    }, // 收款批次
]
export default payment_code
