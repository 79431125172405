import Vue from 'vue'
import router from './router'
import App from './App.vue'
// import Egrid from 'egrid'

// import '@/plugins/vueamap.js' //  vue-amap 高德地图
import '@/plugins/element.js' //  elemnt组件
import {post,get,put,deletes,toJson,isEmpty,postFormData,postMultipart} from '@/plugins/http.js' // 请求方式中间件
import {api} from '@/plugins/api.js'  // API 链接
import {config} from '@/plugins/config.js'  // system config
import {formatDate,formatGoods,clickBtn} from '@/plugins/function.js' // 公共方法
import '../public/fonts/iconfont.css' // 阿里图标
import '../public/style.scss' // 公共CSS
// import skeleton from 'vue-skeleton-component' // 骨架


// import md5 from 'js-md5'; // md5
// import store from './store' // VUEX


// Vue.use(Egrid)

//定义全局变量
Vue.prototype.$api=api;
Vue.prototype.$config=config;
Vue.prototype.$post=post;
Vue.prototype.$postFormData=postFormData;
Vue.prototype.$postMultipart=postMultipart;
Vue.prototype.$get=get;
Vue.prototype.$put=put;
Vue.prototype.$del=deletes;
Vue.prototype.$toJson=toJson;
Vue.prototype.$isEmpty=isEmpty;
// Vue.prototype.$md5=md5;
Vue.prototype.$formatGoods=formatGoods;
Vue.prototype.$clickBtn=clickBtn;
Vue.prototype.hrwjPassword='hrwj2020admin';//操作密码


// 时间格式化
Vue.filter('formatDate', (time) => formatDate(new Date(time*1000), 'yyyy-MM-dd hh:mm'));

Vue.filter('formatDateAuto', (time,str) => formatDate(new Date(time*1000), str));

Vue.config.productionTip = false

// Vue.use(skeleton); // 骨架

// 跳转后返回顶部
router.afterEach(() => {
    window.scrollTo(0,0);
})

new Vue({
    router,
    // store,
    render: h => h(App)
}).$mount('#app')
