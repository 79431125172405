/**
 *@Author:navies
 *@Date:2021-04-12
 *@Project:联盟商家后台管理系统
 */
const activity = [
    //拼手气红包配置列表
    {
        path: "lucky-packets/config-list",
        name: "luckyPacketsConfigList",
        component: () => import("@/views/Admin/activity/lucky-packets/config-list"),
    },
    //拼手气红包配置
    {
        path: "lucky-packets/config",
        name: "luckyPacketsConfig",
        component: () => import("@/views/Admin/activity/lucky-packets/config"),
    },
    //拼手气红包领取列表
    {
        path: "lucky-packets/receive-award",
        name: "luckyPacketsReceiveAward",
        component: () => import("@/views/Admin/activity/lucky-packets/receive-award"),
    },

]
export default activity;
