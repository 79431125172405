import { config } from "./config";
import awzgs from "../api/awzgs";
import shareMall from "../api/modules/shareMall/users";
export const baseUrl = process.env.VUE_APP_API_URL;
export const staticUrl = config.aliStaticUrl;
export const qrCodeUrl = config.qrCodeUrlPath;
export const api = {
    awzgs:awzgs,
    shareMall,
    checkUserLogin: baseUrl + "Admin/check_user_login", // 验证是否已经登录
    login: baseUrl + "system/admin/login", // 登录
    logout: baseUrl + "Admin/logout", // 退出账号 注销

    getUserList: baseUrl + "Admin/users/index",

    // 获取统计数据
    adminGetStatistics: baseUrl + "Admin/index/get_statistics",

    // 获取后台权限栏目
    getPermissionMenus: baseUrl + "system/admin/menu/list",
    getBreadNav: baseUrl + "Admin/menus/get_bread_nav",

    //商品管理-商品列表
    getGoodsList: baseUrl + "system/integral/commodityList",
    skuList: baseUrl + "system/integral/skuList",
    goodsOperation: baseUrl + "system/integral/operation",
    goodsUpload: baseUrl + "system/goods/goods_upload",
    orderlist: baseUrl + "system/order/listByPage", //订单列表
    orderlistExport: baseUrl + "system/order/export", //订单列表导出
    orderDetail: baseUrl + "system/order/detail", //订单详情
    orderStates: baseUrl + "system/order/orderStates", //订单状态
    cancelOrder: baseUrl + "system/order/cancel", //取消订单
    consigneesave: baseUrl + "system/order/consigneesave", //保存收货人信息
    trackingsave: baseUrl + "system/order/trackingsave", //保存物流信息
    logisticsInfo: baseUrl + "system/order/logisticsInfo", //获取物流详情
    logisticsInfos: baseUrl + "system/order/logisticsInfos", //获取物流公司
    goodscategory: baseUrl + "system/goodscategory/listByPage", //获取商品分类
    goodscategoryAdd: baseUrl + "system/goodscategory/add", //添加商品分类
    goodscategoryDel: baseUrl + "system/goodscategory/del", //删除商品分类
    goodscategoryEdit: baseUrl + "system/goodscategory/edit", //编辑商品分类
    goodscategoryTreeNode: baseUrl + "system/goodscategory/listByParentId", //获取商品分类子节点
    addIntegralCommodity: baseUrl + "system/integral/addIntegralCommodity", //添加商品
    commodityDetail: baseUrl + "system/integral/commodityDetail", //编辑商品
    beansectioListByPage: baseUrl + "system/beansection/listByPage", //查询设置
    beansectioEdit: baseUrl + "system/beansection/edit", //查询设置编辑
    beansectioAdd: baseUrl + "system/beansection/add", //查询设置新增
    beansectioDel: baseUrl + "system/beansection/del", //查询设置删除

    //联盟商家
    merchantList: baseUrl + "system/merchant/info/list", //商家列表
    merchantDetail: baseUrl + "system/merchant/info", //商家详情
    updateMerchant: baseUrl + "system/merchant/updateMerchant", //修改商家信息
    getBankNames: baseUrl + "base/bank/names", //获取银行信息
    getBankProvinceList: baseUrl + "base/bank/getProvinceList", //获取银行地区
    getBankByCityId: baseUrl + "base/bank/getBanks", //获取支行
    systemShopList: baseUrl + "system/merchant/systemShopList", //门店列表
    systemShopFixvalueCard: baseUrl+'system/merchant/shop/fixvalue/card', //门店会员卡
    systemShopOrder: baseUrl+'system/shop/order/page', //门店订单
    systemShopDevice: baseUrl+'system/device/listByPage', //门店设备
    systemShopFixvalueCardUpdate: baseUrl+'system/merchant/shop/fixvalue/card/update', //门店会员卡修改
    systemShopDetail: baseUrl + "system/merchant/shop/base/info", //门店详情
    systemShopUpdateById: baseUrl + "system/merchant/shop/base/info/update",//门店修改
    collection: baseUrl + "system/merchant/shop/pay_order", //收款明细
    fenRunEarnings: baseUrl + "system/merchant/shop/fen_run", //收益明细
    fenRunRecord: baseUrl + "system/merchant/shop/huidou/record", //惠豆明细
    fansDetail: baseUrl + "system/merchant/shop/fansDetail", //粉丝明细
    exchangeCommodityOrderRecord: baseUrl + "system/merchant/shop/exchangeCommodityOrderRecord", //惠豆订单
    shopApplyList: baseUrl + "system/merchant/shopApplyList", //商家审核
    shopApplyInfo: baseUrl + "system/merchant/apply/info", //商家审核信息
    shopApplyApprove: baseUrl + "system/merchant/shop/approve", //商家审核
    updApplyMerchantInfo: baseUrl + "system/merchant/updApplyMerchantInfo", //商家审核前编辑
    shopCatgory: baseUrl + "shop/category/0/1/100", //商家审核前经营类型

    merchantImageUpdate: baseUrl + "system/merchant/image/update", //商家修改图片
    incoming: baseUrl + "shop/incoming", //提报进件
    commodityApproveList: baseUrl + "system/merchant/commodity/approve/count/list", //商家商品审核列表
    commodityApproveDetailList: baseUrl + "system/merchant/commodity/approve/list", //商家商品审核详情列表
    commodityApprove: baseUrl + "system/merchant/commodity/approve", //商家商品审核操作
    merchantCategoryList: baseUrl + "system/merchant/category/list", //商家栏目列表
    merchantCategoryChildList: baseUrl + "system/merchant/category/child/list", //商家栏目列表子栏目
    merchantCategoryDel: baseUrl + "system/merchant/category/del", //商家栏目删除
    merchantCategoryAdd: baseUrl + "system/merchant/category/add", //商家栏目新增
    merchantCategoryUpdate: baseUrl + "system/merchant/category/update", //商家栏目修改
    sendMerchantApprovedNotify: baseUrl + "shop/agent/sendMerchantApprovedNotify/", //发送给商户的短信通知
    merchantSyncCheckResultHlj: baseUrl + "system/merchant/syncCheckResultHlj/", //同步惠链接

    // 代理中心
    //   代理管理
    allAreaList: baseUrl + "system/agent/allAreaList", //办公区域
    openAreaList: baseUrl + "system/base/area/openAreaList", //运营区域
    upload: baseUrl + "system/file/upload", //上传
    operationCenter: baseUrl + "system/agent/list/operationCenter", //运营中心列表
    operationCenterDetail: baseUrl + "system/agent/operationCenter", //运营中详情
    serviceList: baseUrl + "system/agent/service/erpGetList", //服务商列表
    bdList: baseUrl + "system/agent/list/bd", //DB列表
    bdServerAreaList: baseUrl + "system/agent/list/bd/server_area", //BD服务区域下拉列表
    getAccount: baseUrl + "user/account", //获取用户信息

    //   服务商

    //   审核列表
    mainexamhistory: baseUrl + "system/agent/list/mainexamhistory", //审核列表
    examhistoryDetail: baseUrl + "system/agent/examhistory/detail", //审查详情
    auditAgentService: baseUrl + "system/agent/bd/auditAgent", //审核提交
    listOperPop: baseUrl + "system/agent/listOperPop", // 获取运营中心选择列表
    listServPop: baseUrl + "system/agent/listServPop", // 获取服务商选择列表

    //   新增/修改代理
    operationCenterAdd: baseUrl + "system/agent/operationCenter/add", //新增运营中心
    operationCenterUpdate: baseUrl + "system/agent/operationCenter/update", //更新运营中心
    serviceAdd: baseUrl + "system/agent/service/erpAddService", //新增服务商
    serviceUpdate: baseUrl + "system/agent/service/update", //新增服务商
    serviceDetail: baseUrl + "system/agent/service", //服务商详情
    bdAdd: baseUrl + "system/agent/bd/add", //新增BD
    bdDetail: baseUrl + "system/agent/bd", //BD详情
    //  分润汇总
    operationsCenterAgent:baseUrl + "system/agent/list/centerAgent",//运营中心列表
    operationsCenterList: baseUrl + "system/agent/fen_run/list/center", //运营中心分润列表
    operationsCenterDetail: baseUrl + "system/agent/fen_run/detail/center", //运营中心分润列表
    serviceProvidersList: baseUrl + "system/agent/fen_run/list/service", //服务商分润列表
    serviceprovidersDetail: baseUrl + "system/agent/fen_run/detail/service", //服务商分润列表
    extensionWorkersList: baseUrl + "system/agent/fen_run/list/bd", //BD分润列表
    extensionWorkersDetail: baseUrl + "system/agent/fen_run/detail/bd", //BD分润列表

    //收款码管理
    payCodeList: baseUrl + "system/qryQrcodeInfoList", //收款码列表
    payCodeShopList: baseUrl + "system/shopList", //收款码商家列表
    payCodeShopBindCode: baseUrl + "system/bindingShopId", //收款码商家绑定
    payCodeBatchList: baseUrl + "system/qryBatchQrcode", //收款码批次列表
    payCodeBatchAdd: baseUrl + "system/addBatchQrcode", //收款码批次新增
    qryQrcodeBatchNoList: baseUrl + "system/qryQrcodeBatchNoList", //获取所有二维码批次号
    payCodeDownload: baseUrl + "system/batchQrcodeDownload", //打包下载支付码
    payCodeDownloadExcel: baseUrl + "system/exportExcel/", //下载Excel支付码

    //设备管理
    deviceTypeList: baseUrl + "system/device/manager/listByPage", //设备类型列表
    deviceBillList: baseUrl + "system/device/billList", //设备类型批次列表
    deviceRefundList: baseUrl + "system/device/pledgerefund/listByPage", //设备退款申请列表
    deviceList: baseUrl + "system/device/listByPage", //设备列表
    deviceDetail: baseUrl + "system/device/detail", //设备详情
    deviceType: baseUrl + "system/device/deviceType", //设备下拉列表
    deviceManufacturer: baseUrl + "system/device/deviceManufacturer", //厂商下拉列表
    deviceTypeNo: baseUrl + "system/device/deviceTypeNo", //获取设备型号下拉列表
    deviceCreate: baseUrl + "system/device/create", //批量添加设备
    deviceUpdate: baseUrl + "system/device/create", //批量修改设备

    //粉丝列表
    membershipList: baseUrl + "system/membership/list", //粉丝列表

    /////////////////////////////系统
    // 权限管理
    // adminList: baseUrl + "system/user/listByPage", //管理员列表
    // adminEdit: baseUrl + "system/user/edit", //管理员编辑
    // adminAdd: baseUrl + "system/user/add", //管理员新增
    // adminDel: baseUrl + "system/user/del", //管理员删除
    // adminRole: baseUrl + "system/role/list", //管理员角色
    adminList: baseUrl + "system/admin/list", //管理员列表
    adminSet: baseUrl + "system/admin/set", //管理员设置
    adminReSetPwd: baseUrl + "system/admin/pwd/set", //管理员设置
    adminDel: baseUrl + "system/admin/del", //管理员删除
    roleSet: baseUrl + "system/admin/role/set", //角色设置
    roleDel: baseUrl + "system/admin/role/del", //角色设置
    roleList: baseUrl + "system/admin/role/list", //管理员角色列表
    adminRoleSet: baseUrl + "system/admin/admin_role/set", //设置管理员角色
    adminRoleDel: baseUrl + "system/admin/admin_role/del", //删除管理员角色
    moduleSet: baseUrl + "system/admin/module/set", //模块设置
    moduleDel: baseUrl + "system/admin/module/del", //模块删除
    moduleReformat: baseUrl + "system/admin/module/reformat", //模块重排
    powerList: baseUrl + "system/admin/power/list", //权限列表
    powerSet: baseUrl + "system/admin/power/set", //权限设置
    powerDel: baseUrl + "system/admin/power/del", //权限删除
    rolePowerIds: baseUrl + "system/admin/role_power/ids", //角色权限
    rolePowerReSet: baseUrl + "system/admin/role_power/reset", //设置角色权限
    adminRolePowerIds: baseUrl + "system/admin/admin_role/power/ids", //管理员角色权限ID集合
    adminSelfPowerIds: baseUrl + "system/admin/admin_self/power/ids", //管理员私人权限ID集合
    adminPowerReSet: baseUrl + "system/admin/admin_power/reset", //设置角色权限
    adminRoleIds: baseUrl + "system/admin/admin_role/ids", //管理员角色ID集合
    // 白名单管理
    addBankCardWhiteListItems: baseUrl + "system/bank/addBankCardWhitelist", //新增银行卡白名单
    getBankCardWhitelistList: baseUrl + "system/bank/getBankCardWhitelistList", //获取银行卡白名单列表
    delBankCardWhitelist: baseUrl + "system/bank/delBankCardWhitelist/", //删除银行卡白名单
    // 系统配置
    systemConfigGroup: baseUrl + "system/systemConfig/getKeyGroupAll", //获取系统配置所有组
    systemConfigList: baseUrl + "system/systemConfig/getList", //获取系统配置列表
    simulateLoginSMS: baseUrl + "system/user/simulateLoginSMS", // 模拟登陆短信


    // 城市站点
    cityList: baseUrl + "system/base/city/list", //城市站点
    cityUpdate: baseUrl + "system/base/city/update", //城市编辑
    cityDel: baseUrl + "system/base/city/del", //城市删除
    cityAdd: baseUrl + "system/base/citySite/add", //城市新增
    cityArea: baseUrl + "system/base/area/list", //省份
    //  区域管理
    districtList: baseUrl + "system/base/district/list", //区域列表
    districtDel: baseUrl + "system/base/district/del", //区域删除
    districtAdd: baseUrl + "system/base/district/add", //区域新增
    districtUpdata: baseUrl + "system/base/district/update", //区域新增
    districtOpenAreaList: baseUrl + "system/base/area/openAreaList", //省份
    // 商圈管理
    businessCircleList: baseUrl + "system/base/businessCircle/list", //商圈列表
    businessCircleDel: baseUrl + "system/base/businessCircle/del", //商圈删除
    businessCircleAdd: baseUrl + "system/base/businessCircle/add", //商圈新增
    businessCircleUpdate: baseUrl + "system/base/businessCircle/update", //商圈编辑

    /////////////////////////////广告管理
    advertList: baseUrl + "system/advert/list", //广告列表
    advertDel: baseUrl + "system/advert/del", //广告删除
    advertAdd: baseUrl + "system/advert/add", //广告新增
    advertUpdate: baseUrl + "system/advert/update", //广告编辑
    //// 广告联盟
    advertisementCreatePlan: baseUrl + "system/plan/createPlan", //广告列表
    advertisementAuditList: baseUrl + "system/plan/getAuditList", //广告列表
    advertisementPlanDetail: baseUrl + "advertising/ad/erp/getPlanDetail/", //审核管理—广告审核页
    advertisementReviewStatistice: baseUrl + "advertising/ad/erp/getReviewStatistice", //审核管理—审核统计
    provinceAll: baseUrl + "system/plan/getBaseAreaAll", //审核管理--获取所有省
    advertisementShopInfo: baseUrl + "system/plan/getShopInfo/", //审核管理--获取店铺信息
    advertisementReviewPlan: baseUrl + "system/plan/reviewPlan", //审核管理—广告审核页—审核
    getUploadAuth: baseUrl + "system/file/getUploadAuth/", //上传视频云

    ///////////////////////////// 交易明细
    accountUserList: baseUrl + "system/account/list", //账户明细
    accountBillList: baseUrl + "system/account/bill/list", //账户明细
    findSuperiorByPhone: baseUrl + "system/membership/findSuperiorByPhone/", //账户明细
    accountListInfo: baseUrl + "system/account/getList", //查询账户信息
    accountStatusUpdate: baseUrl + "system/account/update", //账户状态修改
    accountBillStatistics: baseUrl + "system/accountBill/getAccountBillStatistics", // 账户交易记录统计
    accountBillListTo: baseUrl + "system/accountBill/getList", // 查询账户交易记录
    accountStatistics: baseUrl + "system/account/getAccountStatistics", //账户统计
    getOwnerRoleAll: baseUrl + "system/account/getOwnerRoleAll", //获取所有账户持有人角色
    getAccountTypeAll: baseUrl + "system/account/getAccountTypeAll", //获取所有账户类型
    getAccountBillDayReport: baseUrl + "system/account/getAccountBillDayReport", //账户流水贡献值日报表

    //交易查询
    traderDetailStatistics: baseUrl + "system/pay/trader/detail/getTraderDetailStatistics", //交易明细-统计
    traderDetail: baseUrl + "system/pay/trader/detail/list", //交易明细
    traderDetailExport: baseUrl + "system/pay/trader/detail/list/export", //交易明细导出
    traderTotalDay: baseUrl + "system/pay/total/day/list", //商户日汇总
    traderTotalDayExport: baseUrl + "system/pay/total/day/list/export", //商户日汇总导出
    traderPlanOrderList: baseUrl + "system/merchantPlanOrder/getList", //购买礼包列表

    //代理选项
    bdNamesOption: baseUrl + "system/agent/bd/names", //代理商下拉选项
    serviceNamesOption: baseUrl + "system/agent/service/names", //服务商下拉选项

    //提现管理
    profitCashOrderList: baseUrl + "system/profitCashOrder/getList", //提现明细
    profitCashOrderTotal: baseUrl + "system/profitCashOrder/getCashOrderStatistics", //提现统计
    profitCashOrderListExport: baseUrl + "system/profitCashOrder/export", //提现列表导出Excel
    profitCashLimit: baseUrl + "system/profitCashLimit/getList", //风控列表
    profitCashLimitAdd: baseUrl + "system/profitCashLimit/add", //风控新增
    profitCashLimitEdit: baseUrl + "system/profitCashLimit/updateProfit", //风控新增
    profitCashLimitDel: baseUrl + "system/profitCashLimit/disable/", //风控禁用
    getUserInfoByPhone: baseUrl + "system/profitCashLimit/getUserInfoByPhone/", //手机号获取风控信息
    getProfitById: baseUrl + "system/profitCashLimit/getProfitById/", //风控详情信息
    getWithdrawApplyList: baseUrl + "system/profitCashOrder/getWithdrawApplyList", //提现申请列表
    markUpPlayMoney: baseUrl + "system/profitCashOrder/markUpPlayMoney/", //提现申请列表-标记打款
    profitCashOrderCalculation: baseUrl + "system/profitCashOrder/calculation",//提现申请列表-发票
    profitCashOrderUpdRealAmount: baseUrl + "system/profitCashOrder/updRealAmount",//提现申请列表-发票录入-修改到账

    // 任务管理
    taskUserHeadStatistics: baseUrl + "system/cash/task/user/headStatistics",//任务列表-头部统计
    taskUserHeadList: baseUrl + "system/cash/task/user/list",//任务列表
    taskUserHeadReview: baseUrl + "system/cash/task/user/review",//任务列表-审查
    taskUserDetail: baseUrl + "system/cash/task/user/taskDetail/",//任务列表-详情
    taskUserBackGxz: baseUrl + "system/cash/task/user/goBackGxz/",//任务列表-回退贡献值
    taskUserListExport: baseUrl + "system/cash/task/user/export",//任务列表--导出
    taskCashHeadStatistics: baseUrl + "system/cash/task/headStatistics",//任务列表--头部统计
    taskCashList: baseUrl + "system/cash/task/list",//任务列表
    taskCashSaveTask: baseUrl + "system/cash/task/saveTask",//任务列表--新增
    taskCashEditTask: baseUrl + "system/cash/task/editTask",//任务列表--编辑
    taskCashtaskDetail: baseUrl + "system/cash/task/taskDetail/",//任务列表--详情
    taskCashEditStatus: baseUrl + "system/cash/task/editStatus",//任务列表--编辑状态

    //活动管理
    activityLuckyPacketsCreateRedTask: baseUrl + "system/red/task/createRedTask",//拼手气红包--创建
    activityLuckyPacketsUpdateRedTask: baseUrl + "system/red/task/updateRedTask",//拼手气红包--编辑
    activityLuckyPacketsInfoRedTask: baseUrl + "system/red/task/getRedTaskInfo/",//拼手气红包--详情
    activityLuckyPacketsDelRedTask: baseUrl + "system/red/task/deleteRedTask/",//拼手气红包--删除
    activityLuckyPacketsList: baseUrl + "system/red/task/list",//拼手气红包--列表


};
